import { theme } from '@faxi/web-component-library';
import { flex, phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const EarnedRewards = styled.div`
  .kinto-earned-rewards {
    &__actions {
      ${flex('row', 'space-between', 'center')};

      width: 100%;
      margin-top: ${theme.sizes.SIZE_32};

      ${phablet(css`
        flex-direction: column;
        align-items: flex-start;
      `)}
    }

    &__table {
      margin-top: ${theme.sizes.SIZE_20};
    }

    &__reward-is-hidden {
      color: var(--SHADE_1_1);
    }
  }
`;
