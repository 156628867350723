import { SelectOption } from '@faxi/web-component-library';
import { Dayjs } from 'dayjs';
import {
  Depot,
  DepotAction,
  DepotActions,
  DepotTrigger,
  DepotTriggerName,
  GamificationDepotType,
} from 'models';

export type CreateCampaignForm = {
  name?: string;
  start_date?: Dayjs | '';
  end_date?: Dayjs | '';
  condition: DepotTriggerName;
  per_day?: SelectOption;
  rewards?: SelectOption[];
  participants?: string[] | '';
  state?: string;
  instructions?: string;
  tags: SelectOption[];
  exclude: SelectOption[];
  points?: number;
  repeatable?: string;
  end_date_type?: 'date' | 'never';
  passengers?: number;
};

const retrieveNestedAction = (
  name: DepotActions,
  action?: Partial<DepotAction>
): Partial<DepotAction> | null => {
  if (!action) return null;

  if (action.name === name) {
    return action;
  }

  if (!action.data.passes) {
    return null;
  }

  return retrieveNestedAction(name, action.data.passes[0]);
};

const generateDepotAction = (
  name: DepotActions,
  data: Record<string, any> | null,
  passes: Partial<DepotAction>[],
  condition?: boolean
): Partial<DepotAction> =>
  typeof condition === 'undefined' || condition
    ? {
        name,
        data: {
          ...(data || {}),
          passes,
        },
      }
    : passes[0];

const mapCampaignFormDataForApi = (
  {
    name,
    start_date,
    end_date,
    rewards,
    condition,
    instructions,
    tags,
    participants,
    exclude,
    points,
    repeatable,
    triggers,
    end_date_type,
    passengers,
    ...rest
  }: CreateCampaignForm & { triggers?: DepotTriggerName[] },
  organisationId: number,
  type: GamificationDepotType
) => {
  const createCampaignData: Partial<
    Omit<Depot, 'triggers'> & { triggers: Partial<DepotTrigger>[] }
  > = {
    type: 'gamification',
    name,
    start_date: start_date ? `${start_date.format('YYYY-MM-DD')} 00:00` : '',
    end_date:
      end_date_type === 'date' && end_date
        ? `${end_date.format('YYYY-MM-DD')} 23:59`
        : '',
    data: {
      name,
      organisation_id: +organisationId,
      rewards:
        Number(rewards?.length) > 0
          ? rewards?.map(({ value }) => ({
              id: value,
              ...(type === 'accumulation' && points && { threshold: +points }),
              limit:
                type === 'accumulation'
                  ? //for accumulation campaigns take input value only if
                    //Unlimited number of rewards isnt selected
                    (rest as Record<string, string>)?.[
                      `reward_count_type_${value}`
                    ] === 'limitedRewards'
                    ? +(rest as Record<string, string>)?.[
                        `reward_count_accumulation_${value}`
                      ]
                    : //if it is selected set undefined be expected that
                      undefined
                  : //this is case for regular campaings
                    +(rest as Record<string, string>)?.[
                      `reward_count_${value}`
                    ],
            }))
          : [],
    },
    actions: [
      generateDepotAction(
        'if-accumulated',
        { threshold: points, type: 'points', repeatable: +repeatable! },
        [
          generateDepotAction(
            'if-tag',
            { tags: tags?.map((t) => t.value) },
            [
              // TODO: uncomment this for release6.0
              // generateDepotAction(
              //   'if-participants-number',
              //   { threshold: +passengers! },
              //   [
              generateDepotAction('if-is-participant', { participants }, [
                generateDepotAction(
                  'if-exclude',
                  {
                    exclude: (exclude || []).map((e) => e.id),
                  },
                  [
                    generateDepotAction(
                      type === 'accumulation'
                        ? 'if-accumulated-reward'
                        : 'if-available-reward',
                      null,
                      []
                    ),
                  ],
                  Number(exclude?.length) > 0
                ),
              ]),
              //   ],
              //   JSON.stringify(participants) === JSON.stringify(['driving'])
              // ),
            ],
            //for accumulation campaign we dont set tags
            Number(tags?.length) > 0 && type !== 'accumulation'
          ),
        ],
        type === 'accumulation'
      ),
    ],
    triggers: triggers?.map((trigger) => ({ name: trigger })),
    instructions,
  };

  return createCampaignData;
};

export { retrieveNestedAction, mapCampaignFormDataForApi };
