import { useContext, useMemo, FC, PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getColor } from '@faxi/web-component-library';

import { Icon } from 'components';
import {
  Depot,
  DepotTags,
  RewardType,
  RewardTypes,
  RewardVoucherTypeData,
  REWARD_TYPES,
} from 'models';
import { CampaignContext, UserContext } from 'store';
import { INameExtended } from 'components/Icon';
import useConstants from '../../../../useConstants';
import Each from 'helpers/Each';
import { retrieveNestedAction } from '../../utils';

import * as Styled from './CampaignDetails.styles';

const REWARD_INSTANCE_TRANSLATIONS = {
  [REWARD_TYPES.MESSAGE_REWARD]: 'rewards-title_regular_reward',
  [REWARD_TYPES.PARKING_REWARD]: 'rewards-parking_spaces',
  [REWARD_TYPES.VOUCHER_REWARD]: 'rewards-field_placeholder_reward_type',
} as Record<RewardTypes, string>;

type DetailDataType = {
  title: string;
  subtitle?: string;
  className?: string;
};
const DetailData: FC<PropsWithChildren<DetailDataType>> = (props) => {
  const { className, title, subtitle, children } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames('campaign-details__detail', className)}>
      <div className="campaign-details__detail__title">{t(title)}</div>
      {subtitle && (
        <div className="campaign-details__detail__subtitle">{t(subtitle)}</div>
      )}
      <div className="campaign-details__detail__values">{children}</div>
    </div>
  );
};

type CampaignDetailsProps = {
  campaign: Partial<Depot>;
  // to distinguish details in CreateNewCampaignModal from SelectedCampaignAside
  asideInCampaignList?: boolean;
  campaignState?: JSX.Element;
  className?: string;
};

const CampaignDetails = (props: CampaignDetailsProps) => {
  const {
    campaign,
    asideInCampaignList = false,
    campaignState,
    className,
  } = props;

  const { t } = useTranslation();

  const {
    userPreferences: { dateFormat },
  } = useContext(UserContext);
  const { rewards } = useContext(CampaignContext);

  const { rewardWinners, statusOptions, directionOptions } = useConstants();

  const previewSelectedRewards = useMemo(
    () => (
      <Each
        of={(rewards || [])?.filter(({ id: rewardId }) =>
          campaign?.data?.rewards
            ?.map(({ id }) => `${id}`)
            .includes(`${rewardId}`)
        )}
        render={({ id, instances, type, name, data }: RewardType) => (
          <div className="campaign-details__reward-container">
            <div className="campaign-details__detail__title">
              {t('reward_download_page')}
            </div>

            <div className="campaign-details__detail__data campaign-details__detail__data--reward">
              {name}

              {type !== 'voucher-reward' && (
                <div className="campaign-details__detail__data--reward-limit">
                  {` (${
                    (campaign?.data?.rewards as RewardType[])?.find(
                      (rew) => +rew.id === id
                    )?.limit ||
                    `${t('reward_campaign-unlimited_number_of_rewards')})`
                  }
                   ${
                     (campaign?.data?.rewards as RewardType[])?.find(
                       (rew) => +rew.id === id
                     )?.limit
                       ? `${t('global-reward_s')} ${t(
                           'campaigns-reward_repeat_per_day'
                         )}`
                       : ''
                   }
                    `}
                </div>
              )}
            </div>

            <div className="campaign-details__detail__instances">
              <p className="campaign-details__detail__title">
                {t(REWARD_INSTANCE_TRANSLATIONS[type as RewardTypes])}
              </p>

              {type === 'voucher-reward' ? (
                <div className="campaign-details__detail__data">
                  {t('rewards-reward_type_codes_number', {
                    number: (data as RewardVoucherTypeData)?.number_of_codes,
                  })}
                </div>
              ) : (
                instances &&
                instances?.length > 0 && (
                  <Each
                    containerAs="div"
                    className="campaign-details__reward-instances"
                    of={instances}
                    render={(i) => (
                      <div className="campaign-details__detail__data">
                        {`${i.name} ${
                          i.quantity > 1 ? `(x${i.quantity})` : ''
                        }`}
                      </div>
                    )}
                  />
                )
              )}
            </div>
          </div>
        )}
      />
    ),
    [campaign, rewards, t]
  );

  const previewCampaignType = useMemo(
    () =>
      retrieveNestedAction('if-accumulated', campaign?.actions?.[0])
        ? t('rewards-title_accumulation_campaign')
        : t('rewards-title_regular_campaign'),
    [campaign?.actions, t]
  );

  const previewAccumulationCampaignDetails = useMemo(() => {
    const action = retrieveNestedAction(
      'if-accumulated',
      campaign?.actions?.[0]
    );

    if (action) {
      return {
        points: action?.data?.threshold,
        repeatable: action?.data?.repeatable,
      };
    }
  }, [campaign]);

  const previewSelectedWinners = useMemo(
    () =>
      rewardWinners
        .filter((rewardWinner) => {
          const action = retrieveNestedAction(
            'if-is-participant',
            campaign?.actions?.[0]
          ) as {
            name: 'if-tag';
            data: { participants: string[] };
          } | null;

          return action?.data.participants?.includes(`${rewardWinner.value}`);
        })
        .map((rewardWinner) => (
          <div
            className="campaign-details__detail__data"
            key={`${rewardWinner.value}`}
          >
            <Icon
              name={rewardWinner.icon as INameExtended}
              color={getColor('--PRIMARY_1_1')}
            />
            {rewardWinner.label}
          </div>
        )),
    [campaign?.actions, rewardWinners]
  );

  const previewDirectionCondition = useMemo(() => {
    const action = retrieveNestedAction('if-tag', campaign.actions?.[0]) as {
      name: 'if-tag';
      data: { tags: DepotTags[] };
    } | null;

    return action?.data.tags?.map((tag: string) => (
      <div className="campaign-details__detail__data" key={tag}>
        {directionOptions.find((direction) => direction.value === tag)?.label}
      </div>
    ));
  }, [campaign.actions, directionOptions]);

  const previewPassengerCount = useMemo(() => {
    const actions = retrieveNestedAction(
      'if-participants-number',
      campaign.actions?.[0]
    ) as {
      name: 'if-participants-number';
      data: { threshold: number };
    } | null;

    return actions?.data.threshold ? (
      <DetailData title="global-pickup">
        <div className="campaign-details__detail__data">
          {actions?.data.threshold} {t('global-passenger_s')}
        </div>
      </DetailData>
    ) : (
      <></>
    );
  }, [campaign.actions, t]);

  return (
    <Styled.Container className={classNames('campaign-details', className)}>
      {!asideInCampaignList && <h2>{campaign?.name}</h2>}

      <DetailData title="Campaign type">
        <div className="campaign-details__detail__data">
          {previewCampaignType}
        </div>
      </DetailData>

      <DetailData title="campaign-who_can_win">
        {previewSelectedWinners || '-'}
      </DetailData>

      <DetailData className="rewards" title="global-reward_s">
        {previewSelectedRewards}
      </DetailData>

      <DetailData
        title="global-participants"
        subtitle="campaigns-excluded_users"
      >
        {Number(campaign.excluded?.length) > 0
          ? campaign.excluded?.map(({ label, id }) => (
              <div className="campaign-details__detail__data" key={id}>
                {label}
              </div>
            ))
          : '-'}
      </DetailData>

      {previewAccumulationCampaignDetails && (
        <DetailData title={t('global-accumulation')}>
          <div className="campaign-details__detail__data">
            {previewAccumulationCampaignDetails?.points}
            <span className="campaign-details__detail__data--lowercase">
              {`${t('global-points')}`}
            </span>
          </div>

          <div className="campaign-details__reward-repeatable">
            {`${t('reward_campaign-title_user_can_earn_reward')}: `}

            <span className="campaign-details__reward-repeatable--value">
              {previewAccumulationCampaignDetails?.repeatable
                ? t('reward_campaign-earn_reward_unlimited_times')
                : t('global-once')}
            </span>
          </div>
        </DetailData>
      )}

      {/* For accumulation type campaigns we dont show Conditions sections */}
      {!previewAccumulationCampaignDetails && (
        <DetailData title="global-conditions">
          <div className="campaign-details__detail__data">
            {
              statusOptions.find(
                (condition) => condition.value === campaign?.triggers?.[0].name
              )?.label
            }
          </div>
        </DetailData>
      )}

      <DetailData title="global-direction">
        {previewDirectionCondition || '-'}
      </DetailData>

      {previewPassengerCount}

      <DetailData title="userProfile_status_bio">{campaignState}</DetailData>

      <div className="campaign-details__detail__date-container">
        <div className="campaign-details__detail__date">
          <div className="campaign-details__detail__date__label">
            {t('campaign-start_date')}
          </div>
          <div className="campaign-details__detail__date__value">
            <Icon name="calendar-days" color={getColor('--PRIMARY_1_1')} />
            <div>{dayjs(campaign?.start_date).format(dateFormat)}</div>
          </div>
        </div>

        <div className="campaign-details__detail__date">
          <div className="campaign-details__detail__date__label">
            <div>{t('campaign-end_date')}</div>
          </div>
          <div className="campaign-details__detail__date__value">
            <Icon name="calendar-days" color={getColor('--PRIMARY_1_1')} />
            <div>
              {campaign?.never !== 'never' && campaign?.end_date
                ? dayjs(campaign?.end_date).format(dateFormat)
                : t('gamification-campaigns_end_date_never')}
            </div>
          </div>
        </div>
      </div>
    </Styled.Container>
  );
};

export default CampaignDetails;
