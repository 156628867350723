import { ReactNode } from 'react';
import { ObjectValues } from './Generics';

export const REWARD_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DISABLED: 'disabled',
} as const;
export type RewardStatus = ObjectValues<typeof REWARD_STATUS>;

export const REWARD_TYPES = {
  PARKING_REWARD: 'parking-reward',
  MESSAGE_REWARD: 'message-reward',
  VOUCHER_REWARD: 'voucher-reward',
} as const;

export type RewardTypes = ObjectValues<typeof REWARD_TYPES>;

export type RewardInstance = {
  id: number;
  description: string;
  name: string;
  quantity: number;
  reward_id: number;
  created_at: string;
  updated_at: string;
};

export type RewardVoucherTypeData = {
  number_of_codes: number;
  voucher_id: number;
  voucher_name: string;
};

export type RewardType = {
  id: number;
  community_id?: number;
  type: RewardTypes;
  name: string;
  nameNode?: ReactNode;
  description?: string;
  redeem?: string;
  status?: RewardStatus;
  image?: string | ReactNode;
  file?: File | string;
  data?: any[] | RewardVoucherTypeData;
  created_at: string;
  updated_at: string;
  limit?: number;
  modifiable?: boolean;
  instances?: RewardInstance[];
};

export type EarnedReward = {
  id: number;
  user_id: number;
  user: string;
  reward_id: number;
  reward: string;
  campaign_id: number;
  campaign: string;
  date: string;
  instance_name: string;
  type: RewardTypes;
  vrn: string;
  depot_instance_reward_id?: number;
  hidden?: 0 | 1;
};
